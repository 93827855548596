<template>
  <div>
    <vs-tabs class="invoice-list">
      <vs-tab label="Nurse Details">
        <nurse-detail
          fetchClinicsBy="superAdmin"
          :canDelete="true"
          :inTabEdit="true"
          :adminApprovalVar="true"
        />
      </vs-tab>
      <!-- <vs-tab label="Organizations">
        <associated-organization-table :userId="nurseId" />
      </vs-tab> -->
      <vs-tab label="Clinics" id="clinicsTab">
            <associated-clinics
              :associatedIds="
                nurseData.associatedClinicIds.map((e) => e.clinicId)
              "
              :inTabEdit="true"
              :userId="this.$route.params.nurseId"
              userTypeToEdit="nurse"
            />
      </vs-tab>
      <vs-tab label="Treatments" id="treatmentsTab">
        <vs-card>
          <template v-slot:header>
            <h4>Nurse Treatment History</h4>
          </template>
          <NurseTreatmentHistory
            :nurseDetails="nurseData"
          ></NurseTreatmentHistory>
        </vs-card>
      </vs-tab>
      <vs-tab label="Permissions" id="permissionsTab" v-if="staffIsNotOrgOwner">
          <Permissions :name="nurseData.firstName" :id="nurseData._id" :isNurseType="true"/>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import nurseDetail from "../../components/nurses/nurseDetail.vue";
import AssociatedClinics from "../../components/org-owner/others/associatedClinics.vue";
import NurseTreatmentHistory from "../../../views/nurse/history/TreatmentHistory.vue";
import AssociatedOrganizationTable from "../../components/organization/associatedOrganizationTable.vue";
import Permissions from "../../components/org-owner/others/permissions.vue";
import { mapActions } from "vuex";

export default {
  components: {
    nurseDetail,
    AssociatedClinics,
    NurseTreatmentHistory,
    AssociatedOrganizationTable,
    Permissions,
  },
  data: () => ({
    nurseData: {
      _id: null,
      firstName: "",
      lastName: "",
      email: "",
      associatedClinicIds: [],
    },
    nurseId: null,
  }),
  methods: {
    ...mapActions("nurse", ["fetchNurseDetails"]),
    async getNurseDetail(nurseId) {
      await this.fetchNurseDetails(nurseId).then((res) => {
        const nurse = res.data.data;
        this.nurseData = {
          _id: nurseId,
          firstName: nurse.firstName,
          lastName: nurse.lastName,
          associatedClinicIds: nurse.associatedClinicIds,
        };
      });
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
  },
  async created() {
    this.nurseId = this.$route.params.nurseId;
    await this.getNurseDetail(this.nurseId);
  },
  computed: {
    staffIsNotOrgOwner() {
      return (this.$store.state.AppActiveUser.userRole === "doctor" || 
             this.$store.state.AppActiveUser.userRole === "nurse"  || 
             this.$store.state.AppActiveUser.userRole === "basic") &&
             (localStorage.getItem( `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`) != 'Org Owner' ||
             this.$store.state.AppActiveUser.id != this.nurseId)
    }
  }
};
</script>
